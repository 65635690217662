.App {
  text-align: center;
  background-color: black;
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
  font-family: 'Rubik';
}

.App::-webkit-scrollbar {
  display: none;
}

.App {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.Nav {
  /* background-image: linear-gradient(rgb(0, 0, 0), rgba(0, 0, 0, 0)); */
  z-index: 2 !important;
  /* position: absolute !important; */
}

.NavDiv {
  height: 10vh !important;
  /* z-index: 2 !important; */
  /* position: relative !important; */
  /* height: 15vh !important */
}

.NavTitle {
  /* font-family: Girassol; */
  font-size: 52px;
}

.NavTitle:hover {
  /* letter-spacing: 4px; */
  /* font-size: 56px; */
  color: #39627F
}

.socialContact {
  border: 2px solid white;
  border-radius: 25px;
  margin-right: 10px;
}

.socialContact:hover {
  background-color: #39627F
}

.ProjectsButton:hover {
  background-color: #39627F !important;
}

.HorizontalLine {
  height: 1px;
  background-color: #39627F;
}

.AnimatedVerticalLineLeft {
  height: 74vh !important;
  width: 3px !important;
  background-color: #39627F;
}

.DylansInfoPicture {
  /* background-color: rgb(255, 255, 255); */
  height: 70vh !important;
  width: 75vw !important;
  margin-left: 4.3vw;
  margin-top: 2vh;
  /* padding-right: 18vw !important; */
  /* background-image: url("./Images/home-background.jpg"); */
  /* background-repeat: no-repeat; */
  /* background-size: cover; */
  /* opacity: 0.4; */
  /* margin: auto; */
}

.HomeSidePicture {
  height: 74vh !important;
  width: 18vw !important;
  background-image: url("./Images/vertical-background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.7;
}

.DylansInfoPictureDiv {
  height: 74vh !important;
  width: 82vw !important;
}

.ScrolldownAnimation {
  background-color: white;
  height: 50px !important;
  width: 2.5px !important;
  /* box-shadow: 12px 12px 2px 1px rgba(0, 0, 255, .2); */
}

.Scrolldown {
  color: white;
  writing-mode: vertical-rl;
  text-orientation: mixed;
}

.TopLeftPicture {
  background-image: url("./Images/home-background.jpg");
  width: 30vw;
  height: 30vh;
  background-repeat: no-repeat;
  background-size: cover;
}

.BottomRightPicture {
  background-image: url("./Images/home-background.jpg");
  width: 30vw;
  height: 40vh;
  background-repeat: no-repeat;
  background-size: cover;
}

.MiddlePicture {
  width: 30vw;
  height: 40vh;
  background-image: url("./Images/home-background-cropped.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  
}

.TopRightBlueDiv {
  background-color: #39627F;
  width:35vw;
  height: 30vh;
  /* opacity: .5; */
}

.BottomRightBlueDiv {
  background-color: #39627F;
  width:25vw;
  height: 50vh;
}

.Projects {
  background-color: black;
  height: 100vh !important;
  width: 100vw !important;
  margin-top: 2vh;
  overflow: hidden;
}

.DungeonDive {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.MainMenuPicture {
  background-image: url("./Images/dungeon-dive-main-menu.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 30vh;
  width: 30vw;
  position: relative;
  z-index: 1;
}

.CombatMapPicture {
  background-image: url("./Images/dungeon-dive-combat-map.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 30vh;
  width: 30vw;
  position: relative;
  top: -37vh;
  position: relative;
  z-index: 1 !important;
}

.CharactersGif {
  background-image: url("./Images/dungeon-dive-characters.gif");
  background-repeat: no-repeat;
  background-size: 50%;
  height: 12vh;
  width: 30vw;
  position: relative;
  z-index: 1 !important;
  left: 20vw;
  bottom: 162vh;
}

.MainTitle {
  background-image: url("./Images/dungeon-dive-title.gif");
  background-repeat: no-repeat;
  background-size: 100%;
  height: 12vh;
  width: 53vw;
  margin-left: 25vw;
  position: relative;
  z-index: 1 !important;
  top: 1vh;
}

.CenterRedDiv {
  background-color:#a31622ab;
  width: 60vw;
  height: 30vh;
  /* z-index: 2 !important; */
}

.RightBlueDiv {
  background-color: #39627F;
  width: 35vw;
  height: 70vh;
}

.BottomLeftDiv {
  width: 25vw;
  height: 20vh;
  background-color: white;
  position: relative;
  bottom: 48vh;
  left: 15vw;
}

.MainInfo {
  position: relative;
  z-index: 1 !important;
  width: 30vw;
  color: white;
  top: 4.3vh;
  left: 2vw;
}

.SmallKeyFeatures {
  /* background-color: red; */
  width: 50vw;
  height: 20vh;
  position: relative;
  z-index: 1 !important;
  left: 10vw;
  color: white;
}

.LargeKeyFeature {
  width: 40vw;
  height: 20vh;
  position: relative;
  z-index: 1 !important;
  left: 55vw;
  bottom: 35vh;
  color: rgb(255, 255, 255);
}

.MainInfoText {
  font-size: 32px
}

.Title {
  color: white;
  font-size: 100px;
}

/* Screen Size around 21inch wide screen */
@media screen and (max-width: 1920px) and (max-height: 980px) {
  .NavTitle {
    position: relative;
    bottom: .75vh;
  }
  .ProjectsDiv {
    position: relative;
    top: -1vh;
  }
  .socialContact {
    position: relative;
    top: -1vh;
  }
  .TopRightInfoTextBottom {
    position: relative;
    top: -1.75vh !important
  }
  .Plan {
    position: relative;
    bottom: 2vh !important
  }
  .Design {
    position: relative;
    top: -1vh !important;
  }
  .Build {
    position: relative;
    top: 1vh !important
  }
  .Create {
    position: relative;
    top: 1vh !important;
  }
  .LargeKeyFeatureText {
    position: relative;
    top: 10vh !important
  }
  .MainMenuPicture {
    background-size: contain !important;
    position: relative;
    top: 2vh !important;
    left: 5vw !important
  }
  .CombatMapPicture {
    background-size: contain !important;
    position: relative;
    left: 1vw !important
  }
}

/* Screen Size around 15inch macbook */
@media screen and (max-width: 1920px) and (max-height: 1090px) {
  .NavDiv {
    height: 8vh !important;
  }
  .Title {
    color: white;
    font-size: 100px;
  }
  .ProjectsDiv {
    position: relative;
    bottom: .5vh
  }
  .socialContact {
    position: relative;
    bottom: .5vh
  }
  .TopRightInfoTextBottom {
    position: relative;
    top: 0vh;
  }
  .GrayDiv {
    position: relative;
    top: 2vh;
  }
  .Plan {
    position: relative;
    bottom: 0vh
  }
  .Build {
    position: relative;
    top: 3vh
  }
  .Design {
    position: relative;
    top: 1.5vh;
  }
  .Create {
    position: relative;
    top: 3vh;
  }
  .MainTitle {
    position: relative;
    top: 2vh
  }
  .CombatMapPicture {
    max-width: 30vw;
    max-height: 25vh;
    position: relative;
    right: 2vw;
    object-fit: cover;
    top: -30vh;
    background-size: contain !important;
  }
  .MainMenuPicture {
    max-height: 25vh;
    object-fit: cover;
    background-size: contain !important;
    position: relative;
    top: 2vh !important;
    left: 2vw !important
  }
  .SmallKeyFeaturesText {
    position: relative;
    right: 10vw;
    top: 4vh
  }
  .LargeKeyFeatureText {
    position: relative;
    top: 6vh
  }
  .RightBlueDiv {
    position: relative;
    top: 5vh;
  }
  .CenterRedDiv {
    position: relative;
    top: 5vh;
  }
  .BottomLeftDiv {
    position: relative;
    bottom: 43vh;
    left: 15vw;
  }
  .CharactersGif {
    position: relative;
    left: 20vw;
    bottom: 157vh;
  }
}

/* Screen Size around 13inch macbook */
@media screen and (max-width: 1540px) and (max-height: 870px) {
  .Title {
    font-size: 84px !important
  }
  .TopRightInfoText {
    font-size: 40px !important;
  }
  .TopRightInfoTextBottom {
    font-size: 48px !important;
    position: relative;
    left: 1vw;
    bottom: 1vh
  }
  .GrayDiv {
    position: relative;
    top: 2.5vh;
    right: 1.5vw;
  }
  .Plan {
    position: relative;
    top: -3vh !important;
  }
  .Design {
    position: relative;
    top: -3vh !important;
  }
  .Build {
    position: relative;
    top: -2vh !important;
  }
  .Create {
    position: relative;
    top: -3vh !important;
  }
  .ScrolldownText {
    position: relative;
    left: .25vw
  }
  .MainInfoText {
    position: relative;
    bottom: 2vh;
    font-size: 28px !important;
  }
  .SmallKeyFeaturesText {
    font-size: 42px !important;
    position: relative;
    right: 5vw;
    top: 2vh
  }
  .CombatMapPicture {
    max-width: 30vw;
    max-height: 25vh;
    position: relative;
    right: 2vw;
    object-fit: cover;
    background-size: contain !important;
  }
  .MainMenuPicture {
    max-height: 25vh;
    background-size: fill;
    object-fit: cover;
    background-size: contain !important;
  }
  .LargeKeyFeatureText {
    position: relative;
    top: 6vh
  }
  .RightBlueDiv {
    position: relative;
    top: 5vh;
  }
  .CenterRedDiv {
    position: relative;
    top: 5vh;
  }
  .BottomLeftDiv {
    position: relative;
    bottom: 43vh;
    left: 15vw;
  }
  .CharactersGif {
    position: relative;
    left: 20vw;
    bottom: 157vh;
  }
}

/* Screen Size around Macbook air */
@media screen and (max-width: 1450px) and (max-height: 770px) {
  .Title {
    font-size: 72px !important;
  }
  .TopRightInfoText {
    font-size: 36px !important;
  }
  .TopRightInfoTextBottom {
    font-size: 44px !important;
    position: relative;
    top: 0vh !important;
    right: 1vw !important;
  }
  .GrayDiv {
    position: relative;
    top: 1.4vh !important;
    right: 2vw !important;
  }
  .Plan {
    position: relative;
    bottom: 4vh !important;
    font-size: 48px !important;
  }
  .Design {
    position: relative;
    top: -4vh !important;
    font-size: 48px !important;
  }
  .Build {
    position: relative;
    top: -4vh !important;
    font-size: 48px !important;
  }
  .Create {
    position: relative;
    top: -5vh !important;
    font-size: 72px !important;
  }
  .ScrolldownText {
    position: relative;
    left: .25vw;
    bottom: 1.5vh;
    font-size: 14px;
  }
  .MainInfoText {
    font-size: 24px !important;
    position: relative;
    top: 0vh
  }
  .SmallKeyFeaturesText {
    font-size: 36px !important;
    position: relative;
    right: 5vw;
    top: 2vh
  }
}

@media screen and (max-width: 1450px) and (max-height: 720px) {
  .ProjectText {
    font-size: 20px !important;
  }
  .GrayDiv {
    position: relative;
    top: -.0vh !important;
  }
  .Plan {
    position: relative;
    top: -5vh !important;
  }
  .Design {
    position: relative;
    top: -6vh !important;
  }
  .Build {
    position: relative;
    top: -6vh !important;
  }
  .Create {
    position: relative;
    top: -7vh !important;
  }
  .SmallKeyFeatures {
    position: relative;
    left: 4vw !important;
    top: 2vh;
  }
}
